import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ReactMarkdown from "react-markdown"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <h1>Hi!</h1>
    <p>This is Tristan Schorn's blog, which is used for rambling about various things he cares about.</p>
    
    {data.allStrapiArticle.edges.map(document => (
      <p key={document.node.id}>
        <h2>
          <Link to={`/${document.node.slug}`}>
            {document.node.title}
          </Link>
        </h2>
        <small>Posted on {document.node.published_at}</small>
        <ReactMarkdown children={document.node.excerpt} />
      </p>
    ))}
    
    <p>
      <Link to="/page-2/">Go to page 2</Link> <br />
    </p>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiArticle(sort: { fields: [published_at], order: DESC }) {
      edges {
        node {
          id
          slug
          title
          excerpt
          content
          published_at(formatString: "YYYY-MM-DD")
        }
      }
    }
  }
`